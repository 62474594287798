import React from "react"
import Layout from "../components/Layout/Layout"

const Privacy: React.FC = () => {
  return (
    <Layout>
      <article
        style={{ padding: "20px", maxWidth: "1000px", margin: "0 auto" }}
      >
        <h1>Privacy Policy Palapa Sp. z o.o.</h1>
        <h2>§1. General information</h2>
        <ol>
          <li>
            Privacy policy outlines the rules for processing and protection of
            personal data of Users when using Administrators websites
          </li>
          <li>
            A User, for the needs of this policy is defined as a person
            contacting and/or ordering services provided by the Administrator.
          </li>
          <li>
            Palapa sp z o.o., NIP: 5272934529, REGON: 38683067900000, based in
            Warsaw 01-171, Młynarska 42/115, e-mail: hello@palapa.tech is an
            Administrator of Personal Data according to the Regulation (EU)
            2016/679 of the European Parliament and of the Council of 27 April
            2016 on the protection of natural persons with regard to the
            processing of personal data and on the free movement of such data,
            and repealing Directive 95/46/WE (General Data Protection
            Regulation)L 119/40 PL Dziennik Urzędowy Unii Europejskiej 4.5.2016.
          </li>
          <li>
            Contact regarding the processing of personal data by the
            Administrator: hello@palapa.tech
          </li>
          <li>
            The scope, purpose and period of data processing is outlined in
            further parts of this Privacy Policy
          </li>
        </ol>
        <h2>
          §2. The purpose of collecting and processing of personal data by the
          Administrator
        </h2>
        <ol>
          <li>
            The user entrusts the Administrator with the processing of personal
            data in order to use the WWW services and for the processing of
            User`s queries and orders made using Administrator`s contact form,
            including sending of personalised business information to the User.
          </li>
          <li>
            The user providing a contact phone number agrees to be contacted by
            Administrator by phone in order to enable the Administrator to
            provide services outlined in point 1 above.
          </li>
          <li>
            The User providing an email address agrees to be contacted by
            Administrator by email in order to enable the Administrator to
            provide services outlined in point 1 above.
          </li>
          <li>
            User’s consent by providing the information is voluntary whereas
            lack of such consent may cause the Administrator to not be able to
            process queries/ orders placed using the contact form.
          </li>
          <li>
            In an event of establishing cooperation between User and
            Administrator access to personal data may need to be granted to
            sub-contractors and affiliated partners of the Administrator. This
            will be clearly communicated to the User and access granted only
            with User’s permission.
          </li>
        </ol>
        <h2>§3. The scope of data processing by Administrator</h2>
        <ol>
          <li>
            Data gathered based on this Privacy Policy consist of all or some of
            the following: <br></br>User data:
            <ul>
              <li>Name and surname</li>
              <li>Phone number</li>
              <li>Email address</li>
            </ul>
          </li>
          <li>
            The processing of above data includes creating of backup copies of
            data.
          </li>
          <li>
            The website runs an automated system allowing monitoring of user’s
            behavior and adjusting of presented content to user’s interests. The
            system is integrated in the service and each user can opt out or
            voice their reservations at any time.
          </li>
          <li>
            The Administrator uses cookies files in order to ensure the proper
            working of the service, adjusting content to User’s preferences, and
            optimising the use of service`s websites. The cookies files allow
            the identification of the basic parameters of the user’s device
            (e.g. device type, screen resolution, location by country) and
            proper viewing of the website according to user’s requirements.
          </li>
          <li>
            The Administrator of the service uses cookies to gather general and
            anonymous statistical data via Google Analytics tools (cookies
            administrator: Google Inc. USA)
          </li>
          <li>
            The User can change the settings of cookies files by setting
            conditions of access and keeping of cookies files data in browser
            settings. The settings may be adjusted in such way as to block
            automatic processing of cookies by the browser or/and notifying the
            user each time cookies are placed on User’s device. Detailed
            information on use and processing of cookies is available in program
            (browser) settings.
          </li>
          <li>
            The User can delete cookies using browser functions at any time.
          </li>
          <li>
            Restricting the use of cookies may impact the functionality of the
            service`s website.
          </li>
          <li>
            The will notify of the gathering of personal data of Users for
            registration in scope and under conditions stated in GDPR and all
            other regulations.
          </li>
        </ol>
        <h2>§4. The rights of Users whose data is processed</h2>
        <ol>
          <li>
            The Users have the right to:<br></br>
            <ol>
              <li>Access their personal data</li>
              <li>Change their personal data</li>
              <li>Remove their personal data</li>
              <li>Restrict the rights to process personal data</li>
              <li>Move personal data</li>
              <li>File complaints regarding personal data processing</li>
            </ol>
          </li>
          <li>
            In order to exercise above rights the User should contact the
            Administrator at hello@palapa.tech
          </li>
          <li>
            The Administrator will address User’s reservations/ complaints
            without delay noting that removal,restriction,moving or complaints
            regarding data processing may impact the ability to provide relevant
            business information regarding User’s query/order.
          </li>
        </ol>
        <h2>
          §5. Timeframes for keeping and processing of personal data by
          Administrator
        </h2>
        <p>
          The Administrator keeps User’s provided personal data no longer then
          it is required to accurately service the User’s query/order, including
          preparation of personalised business offer and fulfillment of
          Administrators obligations. After that period data is stored only to
          secure fulfillment of User’s obligations to Administrator. User’s can
          at any time request removal of personal data.
        </p>
        <h2>§6. Administrator`s obligations</h2>
        <p>
          The Administrator of personal data is obliged to process personal data
          in a safe and secure manner as per Regulation, especially with regard
          to:
        </p>
        <ol>
          <li>
            Securing the data from unauthorised access, obtaining, changing or
            destruction.
          </li>
          <li>Allow only Administrator authorised access to processed data</li>
          <li>Ensuring appropriate control of processed data</li>
          <li>
            Keeping record of persons authorised to access and process personal
            data with particular care that persons authorised keep the data
            confidential during and after Administrator`s use of them. This will
            be done by signing appropriate NDA and training in keeping data
            confidentiality
          </li>
          <li>
            Keeping records of the methods and technologies used to ensure data
            confidentiality.
          </li>
          <li>
            Ensuring that all electronic systems and devices used to store and
            process personal data comply with Internal Affairs and
            Administration Regulation dated 29th of April 2004 regarding
            personal data documentation as well as technical and organisational
            conditions for systems and devices processing such data.
          </li>
        </ol>
      </article>
    </Layout>
  )
}

export default Privacy
